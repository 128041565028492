import React from 'react';
import { graphql } from 'gatsby';
import Articles from 'components/shared/Articles';

function SingleCategory({ data, pageContext }) {
  const { edges } = data.allMdx;
  const { pagePrefix } = pageContext;

  return (
    <Articles posts={edges} pageContext={pageContext} pagePrefix={pagePrefix} />
  );
}

export const query = graphql`
  query($skip: Int!, $limit: Int!, $tag: String!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                fixed(width: 400) {
                  src
                }
              }
            }
            author
            date
            excerpt
            path
            title
            tags
          }
        }
      }
    }
  }
`;

export default SingleCategory;
